import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formeval'
})
export class FormevalPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let ruleset = args[0] as any;
    let control = args[1] as any;
    let controlname = control.name;

    console.log('transofrm ', control, ruleset);

    for (let i = 0; i < ruleset.schedules.length; i++) {
      try {

        (window as any)[ruleset.schedules[i].name] = parseFloat(ruleset.schedules[i].defaultvalue);
      } catch (err) {
        (window as any)[ruleset.schedules[i].name] = ruleset.schedules[i].defaultvalue;

      }

    }

    let evaluated = eval('' + control.defaultvalue);
    console.log('evaluated', evaluated);

    return evaluated;
  }

  getControlbyName(controls, name) {

  }
}
