<div class="container" fxLayout="row" fxLayoutAlign="center center" [hidden]="afAuth.auth.currentUser" style="height:75vh;"
  fxLayoutGap="0px" fxLayoutGap.xs="0">

  <mat-card>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="{{ 'navigation.signin' | translate }}">
          <input matInput placeholder="Username" class="mb-3" #txtlogin name="username" required>
          <input matInput placeholder="Password" class="mb-3" #txtpass type="password" name="password" required>
          <button class="btn btn-primary btn-lg btn-block" (click)="loginWithEmail(txtlogin.value, txtpass.value)">{{ 'navigation.signin' | translate }}</button>

        </mat-tab>


        <!-- register -->
        <mat-tab label="{{ 'navigation.register' | translate }}">
          <input matInput placeholder="E-Mail" class="mb-3" #txtmailregister type="email" required>
          <input matInput placeholder="Password" class="mb-3" #txtpassregister type="password" name="password" required>
          <button class="btn btn-primary btn-lg btn-block" (click)="emailSignUp(txtmailregister.value,txtpassregister.value)">{{ 'navigation.register' | translate }}</button>
        </mat-tab>
      </mat-tab-group>

    </mat-card-content>
  </mat-card>
</div>
