import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  public databasepath = "logs";
  constructor(private fs: AngularFirestore) {
    //
  }

  private log(message: string, source: string = "", level: string, meta: any) {
    const newentry = {
      datetime: new Date().getTime(),
      message: message,
      source: source,
      level: level,
      meta: meta
    };
    this.fs.firestore.collection(this.databasepath).add(newentry);
  }

  public debug(message: string, source: string = "", meta: any = {}) {
    this.log(message, source, "debug", meta);
  }

  public error(message: string, source: string = "", meta: any = {}) {
    this.log(message, source, "error", meta);
  }

  public warn(message: string, source: string = "", meta: any = {}) {
    this.log(message, source, "warn", meta);
  }
}
