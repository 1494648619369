import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AngularFirestore } from "@angular/fire/firestore";
import { LoggerService } from "src/app/logger.service";
import { map } from "rxjs/operators";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"]
})
export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  filter: string;
  //  dataSource: InfotableDataSource;
  dataSource: MatTableDataSource<any>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ["storename", "name", "email", "registerDate", "lastLogin", "functions"];

  public options: any;

  entries: any;
  constructor(public afs: AngularFirestore, ls: LoggerService, public dialog: MatDialog) {
    this.dataSource = new UsersDataSource();
    this.dataSource.data = [
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null },
      { uid: null, name: '<img src="/assets/ajax-loader.gif" />', lastLogin: "", email: "", registerDate: null }
    ];
    this.entries = afs.collection("log").valueChanges();
    this.options = "option";
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(LogdetailComponent, {
  //     data: { name: "austin" }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
  // openEditDialog(logitem) {
  //   if (!logitem) {
  //     logitem = {};
  //   }

  //   const dialogRef = this.dialog.open(LogdetailComponent, {
  //     data: logitem
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }
  ngOnInit() {
    //  this.dataSource = new InfotableDataSource(this.afs, this.paginator, this.sort);

    this.afs
      .collection("users")
      .snapshotChanges()
      .pipe(


        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data as {} };
          });
        })
      )
      .subscribe(x => {
        this.dataSource.data = <any>x;
        console.log("users ", x);

        this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() { }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  deleteLog(id) {
    console.log("delete " + id);
    this.afs.doc("/users/" + id).delete();
  }
}

export class User {
  public name: string;
  public company: string;
  public street: string;
  public postcode: string;
  public city: string;
  public country: string;
  public taxid: string;
  email: string;
  lastLogin: any;
  registerDate: any;
  provider: string;
  uid: string;
  vms: any;
}

export class UsersDataSource extends MatTableDataSource<any> {
  data: any[];

  constructor() {
    super();
  }

  disconnect() { }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
