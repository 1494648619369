<label class="mat-raised-button btn-file">
  <i class="fa fa-folder-open-o"></i> {{label}}

  <input type="file" accept=".jpg,.png,.jpeg,.gif" style="display: none;" class="file_upload"
    (change)="uploadFile2($event, file)" name="file" id="file" [(ngModel)]="model.file" #file="ngModel">
  <!-- <div [hidden]="fileGehaltsabrechnung.valid || (fileGehaltsabrechnung.pristine && !f2.submitted)" class="text-danger">bitte Datei hochladen!</div> -->
</label>




<div *ngIf="currentUpload">
  <div class="progress" style="margin-bottom: 0px;">
    <div class="progress-bar progress-bar-animated" [ngStyle]="{ 'width': currentUpload?.progress + '%' }"></div>
  </div>
  <span style="font-size:0.8em;min-width:400px;">Datei-Upload: {{currentUpload?.name}} |
    {{currentUpload?.progress}}%</span>
</div>