<section class="bg-brand-one">
  <h2 class="text-white mb-3">{{ 'dashboard.site_title' | translate }}</h2>
  <mat-card class="mb-3">
    <h4>{{ 'dashboard.desc_title' | translate }}</h4>
    <p>{{ 'dashboard.description' | translate }}</p>
    <button class="btn-success" mat-button routerLink="/rulesets" routerLinkActive="active"><i class="fas fa-plus"></i> <span> {{ 'dashboard.cta_create_first_ruleset' | translate }}</span></button>
  </mat-card>
</section>
<section class="bg-white">
  <h3 class="mb-3">Changelog</h3>
  <mat-card class="mb-3">
    <span class="badge bg-brand-secondary text-dark mb-2">V.1.3.6</span>
    <h5 class="mb-3">Eine Änderung</h5>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,</p>
    <ol>
      <li>123</li>
      <li>456</li>
      <li>789</li>
    </ol>
  </mat-card>
</section>
