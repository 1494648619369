import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "logmessage"
})
export class LogmessagePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    if (value.includes("checkout")) {
      return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-shopping-cart accentColorBG "></i>&nbsp;' + value);
    } else if (value.includes("process-due-subscriptions")) {
      return this.sanitizer.bypassSecurityTrustHtml('<i class="fa fa-history accentColorBG "></i>&nbsp;' + value);
    }

    return value;
  }
}
