import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-settings",
  templateUrl: "./new.settings.component.html",
  styleUrls: ["./settings.component.css"]
})
export class SettingsComponent implements OnInit {
  public UPLOADPATH = "useruploads/";
  SERVER = "https://prodopt.herokuapp.com"; // "https://reoapp.herokuapp.com"; // "http://localhost:3000"; // 

  contactForm: FormGroup;
  fontcolor: any;
  user: any;

  constructor(private fb: FormBuilder, public afs: AngularFirestore, public auth: AngularFireAuth, public http: HttpClient, private _snackBar: MatSnackBar) {
    this.contactForm = fb.group({
      firstName: "",
      lastName: "",
      street: null,
      streetno: null,
      zip: null,
      city: "",
      country: "",
      password: "",
      taxrate: null,
      storename: "",
      storeurl: "",
      redirecturl: "",
      shippingrate: null,
      cssrules: "",
      color: "",
      fontsize: null,
      email: "",
      fontcolor: "#000000",
      backgroundcolor: "#e8e8e8",
      chargeshipping: "1",
      sepanetsandbox: "1",
      paypalname: "",
      paypalkey: "",
      braintreemerchantid: "",
      braintreeprivatekey: "",
      braintreepublickey: "",
      sepanetname: "",
      sepanetkey: "",
      logourl: "",
      gcode: "",
      stoken: ""
    });
    console.log('this.contactForm', this.contactForm);

    this.afs.collection("users").doc(this.auth.auth.currentUser.uid).snapshotChanges().subscribe(x => {
      let data = x.payload.data();
      if (!data) {
        data = { settings: {} };
      }
      console.log("x", data);
      this.contactForm.patchValue((<any>data).settings);

      console.log("contactform", this.contactForm);
      this.user = data;
    });
  }

  ngOnInit() { }

  testpaypal() { }

  testsepanet() { }

  testshopify() {
    console.log("shoipify redirect " + this.SERVER + "/getauthurl?shop=" + this.user.settings.storeurl);
    window.location.href = this.SERVER + "/getauthurl?shop=" + this.user.settings.storeurl;

    // this.http.get(this.SERVER + "/getauthurl?shop=" + this.user.settings.storeurl).subscribe(data => {
    //   // reostore1.myshopify.com").subscribe(data => {
    //   console.log("getauthurl ", data);
    // });
  }
  public save() {
    console.log("save ", this.user, this.contactForm.value);

    this.afs.doc("/users/" + this.auth.auth.currentUser.uid).set(JSON.parse(JSON.stringify({ settings: this.contactForm.value })));

    this._snackBar.open("Changes saved", "", { duration: 3000 });
  }


  public backgroundUploaded(event) {
    console.log("background uploaded  ", event);
    //   this.contactForm.value.logourl = event.url;
    this.contactForm.controls['logourl'].setValue(event.url);
    // this.layout.backgroundtype = "uploaded";
  }
  public uploadFailed(event) {
    window.alert("Upload fehlgeschlagen. Versuchen Sie es bitte erneut und prüfen Sie Ihre Internetverbindung.");
  }
}
