<section>


  <h2>{{ 'rulesets.site_title' | translate }}</h2>
  <mat-card class="mb-3">
    <h5>{{ 'rulesets.desc_title' | translate }}</h5>
    <div class="row">

      <div class="col-md-6">

        <p>{{ 'rulesets.description' | translate }}</p>
        <h4>{{ 'rulesets.sync_card.card_title' | translate }}</h4>
        <p>{{ 'rulesets.sync_card.description' | translate }}</p>
      </div>
      <div class="col-md-6">

        <h4>{{ 'rulesets.actions_card.card_title' | translate }}</h4>
        <p>{{ 'rulesets.actions_card.description' | translate }}</p>
        <button mat-raised-button class="btn-block btn-success" (click)="openDialog()"><i class="fas fa-plus"></i>
          {{ 'rulesets.actions_card.create_ruleset' | translate }}</button>
        <button mat-raised-button class="btn-block btn-primary" (click)="getShopifyProducts()"><i class="fas fa-sync"></i> {{ 'rulesets.actions_card.sync_products' | translate }}</button>

      </div>
    </div>

  </mat-card>

  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">

      <mat-expansion-panel *ngFor="let r of sets; " class="mb-3" [expanded]="expansionStates[r.id]" (closed)="panelClosed(r.id)" (opened)="panelOpened(r.id)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>Ruleset: {{ r.name }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-tab-group animationDuration="0ms" [selectedIndex]="r.selectedIndex" (selectedIndexChange)="tabchange(r,$event)">
          <mat-tab label="{{ 'rulesets.tabs.settings' | translate }}">
            <div class="col-12">
              <p class="pt-3 pb-0">{{ 'rulesets.details.name' | translate }}</p>
              <input matInput [(ngModel)]="r.name" placeholder="{{ 'rulesets.details.name' | translate }}" />
              <p class="pt-3 pb-0">{{ 'rulesets.details.products' | translate }}</p>

              <span name="prods" class="autocomplete-select"></span>
              <input name="prods" id="prods" type="hidden" style="display: none; visibility: hidden;" required>
              <ng-multiselect-dropdown matInput class="mb-3" name="prod_input" [placeholder]="'Select Product(s)'" [data]="userproducts" [settings]="dropdownSettings" [(ngModel)]="r.selectedProducts"
                (onSelect)="onItemSelect($event)">
              </ng-multiselect-dropdown>

              <!-- userpdoucts:{{userproducts |json}}
              r.selectedproducts {{r.selectedProducts |json}} -->


              <!--<div *ngFor="let p of r.products; let i = index">
              <mat-select placeholder="" [ngModel]="p.id" (selectionChange)="dochange($event, r, p ,i)">

                <mat-option *ngFor="let sp of shopproducts" [value]="sp.id"
                  [disabled]="selectedpids?.includes(sp.id)">
                  {{ sp.title }}

                </mat-option>


              </mat-select>
              <div style="float:left;width:10%">
                <button color="" class="smallbtn2" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click"
                  mat-raised-button>x</button>&nbsp;

                <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
                  <mat-card style="max-width: 300px">
                    <mat-card-content>
                      <div style="color: red;padding: 10px 0px;"><i class="fa far fa-exclamation-triangle"></i>
                        {{ 'rulesets.actions.delete_msg' | translate }}
                      </div>
                      <button mat-button routerLink=".">{{ 'rulesets.actions.cancel' | translate }}</button>&nbsp;
                      <button mat-flat-button color="warn"
                        (click)="deleteproduct(r, i)">{{ 'rulesets.actions.delete' | translate }}</button>
                    </mat-card-content>
                  </mat-card>
                </mde-popover>
              </div>
              <div style="clear:both"></div>

            </div>

            <button mat-raised-button
              (click)="addproduct(r)">{{ 'rulesets.actions.add_product' | translate }}</button>-->


            </div>
          </mat-tab>
          <mat-tab label="{{ 'rulesets.tabs.controls' | translate }}">
            <div class="col-12">
              <!--<p class="pt-3 pb-0">{{ 'rulesets.controls.desc-add' | translate }}</p>-->
              <span id="text" (click)="addControl(r)" class="badge"><i class="fal fa-plus-circle"></i> {{ 'rulesets.controls.new_control' | translate }}</span>
              <!-- controls start -->


              <div id="controls" class="control-container mt-3">
                <!--<p class="control-text">{{ 'rulesets.controls.drag-here' | translate }}</p>-->
                <div *ngFor="let s of r.controls; let index = index" class="mb-3">
                  <mat-expansion-panel class="mb-3">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <strong>{{s.name}}</strong>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <!-- SETTINGS -->
                      <div class="col-sm-12 col-md-6 col-lg-8">
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <h4>{{ 'rulesets.controls.settings' | translate }}</h4>
                            <p class="pt-0 pb-0">{{ 'rulesets.controls.name' | translate }}</p>
                            <input matInput placeholder="{{ 'rulesets.controls.name' | translate }}" [(ngModel)]="s.name" (change)="update(r,s)">
                            <p class="pt-3 pb-0">{{ 'rulesets.controls.variable' | translate }}</p>
                            <input matInput placeholder="{{ 'rulesets.controls.variable' | translate }}" [(ngModel)]="s.varname">
                            <p class="pt-3 pb-0">{{ 'rulesets.controls.control-type' | translate }}</p>
                            <mat-select class="w-100" [(ngModel)]="s.controltype" [(value)]="s.controltype">
                              <mat-option value="Label">{{ 'rulesets.controls.types.label' | translate }}</mat-option>
                              <mat-option value="Input">{{ 'rulesets.controls.types.input' | translate }}</mat-option>
                              <mat-option value="Numeric">{{ 'rulesets.controls.types.numeric' | translate }}
                              </mat-option>
                              <mat-option value="Select">{{ 'rulesets.controls.types.select' | translate }}
                              </mat-option>
                              <mat-option value="Radio">{{ 'rulesets.controls.types.radio' | translate }}</mat-option>
                              <mat-option value="Checkbox">{{ 'rulesets.controls.types.checkbox' | translate }}
                              </mat-option>
                            </mat-select>
                            <p class="pt-3 pb-0">{{ 'rulesets.controls.css-class' | translate }}</p>
                            <input matInput placeholder="{{ 'rulesets.controls.css-class' | translate }}" [(ngModel)]="s.cssclass">
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <!-- settings -->
                            <div *ngIf="s.controltype === 'Label'">
                              <h4>{{ s.controltype }} {{ 'rulesets.controls.type-settings' | translate }}</h4>
                              <p class="pt-0 pb-0">{{ 'rulesets.controls.options.default_value' | translate }}</p>
                              <input matInput placeholder="{{ 'rulesets.controls.options.default_value' | translate }}" [(ngModel)]="s.defaultvalue" (keyup)="update(r,s)">
                              <p class="pt-3 pb-0">{{ 'rulesets.controls.options.unit' | translate }}</p>
                              <input matInput placeholder="{{ 'rulesets.controls.options.unit' | translate }}" [(ngModel)]="s.unit" (change)="defaultchange(s)">
                              <p class="pt-3 pb-0">{{ 'rulesets.controls.options.formula' | translate }}</p>
                              <textarea rows="5" cols="60" matInput placeholder="{{ 'rulesets.controls.options.formula' | translate }}" [(ngModel)]="s.formula" (keyup)="update(r,s)"></textarea>
                            </div>

                            <div *ngIf="s.controltype === 'Input'">
                              <h4>{{ s.controltype }} {{ 'rulesets.controls.type-settings' | translate }}</h4>
                              <p class="pt-0 pb-0">{{ 'rulesets.controls.options.default_value' | translate }}</p>
                              <input matInput placeholder="{{ 'rulesets.controls.options.default_value' | translate }}" [(ngModel)]="s.defaultvalue" (change)="defaultchange(s)">
                              <p class="pt-3 pb-0">{{ 'rulesets.controls.options.unit' | translate }}</p>
                              <input matInput placeholder="{{ 'rulesets.controls.options.unit' | translate }}" [(ngModel)]="s.unit" (change)="defaultchange(s)">
                            </div>

                            <div *ngIf="s.controltype === 'Numeric'">
                              <h4>{{ s.controltype }} {{ 'rulesets.controls.type-settings' | translate }}</h4>
                              <p class="pt-0 pb-0">{{ 'rulesets.controls.options.default_value' | translate }}</p>
                              <input matInput placeholder="{{ 'rulesets.controls.options.default_value' | translate }}" [(ngModel)]="s.defaultvalue" (change)="defaultchange(s)">
                              <p class="pt-3 pb-0">{{ 'rulesets.controls.options.unit' | translate }}</p>
                              <input matInput placeholder="{{ 'rulesets.controls.options.unit' | translate }}" [(ngModel)]="s.unit" (change)="defaultchange(s)">
                              <p class="pt-3 pb-0">{{ 'rulesets.controls.options.min_value' | translate }}</p>
                              <input matInput type="number" placeholder="{{ 'rulesets.controls.options.min_value' | translate }}" [(ngModel)]="s.min">
                              <p class="pt-3 pb-0">{{ 'rulesets.controls.options.max_value' | translate }}</p>
                              <input matInput type="number" placeholder="{{ 'rulesets.controls.options.max_value' | translate }}" [(ngModel)]="s.max">

                            </div>

                            <div *ngIf="s.controltype === 'Select'">
                              <h4>{{ s.controltype }} {{ 'rulesets.controls.type-settings' | translate }}</h4>
                              <div *ngFor="let o of s.options; let oi = index">
                                <!--<div style="float:left;margin-right:5px;">{{oi+1}})</div>-->

                                <div class="d-flex align-content-stretch flex-row flex-nowrap" style="gap: 10px">
                                  <div>
                                    <p class="pt-0 pb-0">{{ 'rulesets.controls.options.option_value' | translate }}
                                    </p>
                                    <input class="mb-3" matInput placeholder="{{ 'rulesets.controls.options.option_value' | translate }}" [(ngModel)]="o.defaultvalue">
                                  </div>
                                  <div>
                                    <p class="pt-0 pb-0">{{ 'rulesets.controls.options.option_label' | translate }}
                                    </p>
                                    <input class="mb-3" matInput placeholder="{{ 'rulesets.controls.options.option_label' | translate }}" [(ngModel)]="o.label">
                                  </div>
                                  <div>
                                    <p class="pt-0 pb-0">&nbsp;</p>
                                    <button class="btn btn-danger btn-lg" (click)="removeoption(s,oi)"><i class="fas fa-trash"></i></button>&nbsp;
                                  </div>
                                </div>

                              </div>

                              <button class="btn btn-success" (click)="addoption(r,s)"><i class="fas fa-plus"></i> {{
                                'rulesets.controls.options.new_option' | translate }}</button>

                            </div>

                            <div *ngIf="s.controltype === 'Radio'">

                              <h4>{{ s.controltype }} {{ 'rulesets.controls.type-settings' | translate }}</h4>
                              <div *ngFor="let o of s.options; let oi = index">
                                <!--<div style="float:left;margin-right:5px;">{{oi+1}})</div>-->

                                <div class="d-flex align-content-stretch flex-row flex-nowrap" style="gap: 10px">
                                  <div>
                                    <p class="pt-0 pb-0">{{ 'rulesets.controls.options.option_value' | translate }}
                                    </p>
                                    <input class="mb-3" matInput placeholder="{{ 'rulesets.controls.options.option_value' | translate }}" [(ngModel)]="o.defaultvalue">
                                  </div>
                                  <div>
                                    <p class="pt-0 pb-0">{{ 'rulesets.controls.options.option_label' | translate }}
                                    </p>
                                    <input class="mb-3" matInput placeholder="{{ 'rulesets.controls.options.option_label' | translate }}" [(ngModel)]="o.label">
                                  </div>
                                  <div>
                                    <p class="pt-0 pb-0">&nbsp;</p>
                                    <button class="btn btn-danger btn-lg" (click)="removeoption(s,oi)"><i class="fas fa-trash"></i></button>&nbsp;
                                  </div>
                                </div>

                              </div>

                              <button class="btn btn-success" (click)="addoption(r,s)"><i class="fas fa-plus"></i> {{
                                'rulesets.controls.options.new_option' | translate }}</button>

                            </div>

                            <div *ngIf="s.controltype === 'Checkbox'">
                              <h4>{{ s.controltype }} {{ 'rulesets.controls.type-settings' | translate }}</h4>
                              <div *ngFor="let o of s.options; let oi = index">
                                <!--<div style="float:left;margin-right:5px;">{{oi+1}})</div>-->

                                <div class="d-flex align-content-stretch flex-row flex-nowrap" style="gap: 10px">
                                  <div>
                                    <p class="pt-0 pb-0">{{ 'rulesets.controls.options.option_value' | translate }}
                                    </p>
                                    <input class="mb-3" matInput placeholder="{{ 'rulesets.controls.options.option_value' | translate }}" [(ngModel)]="o.defaultvalue">
                                  </div>
                                  <div>
                                    <p class="pt-0 pb-0">{{ 'rulesets.controls.options.option_label' | translate }}
                                    </p>
                                    <input class="mb-3" matInput placeholder="{{ 'rulesets.controls.options.option_label' | translate }}" [(ngModel)]="o.label">
                                  </div>
                                  <div>
                                    <p class="pt-0 pb-0">&nbsp;</p>
                                    <button class="btn btn-danger btn-lg" (click)="removeoption(s,oi)"><i class="fas fa-trash"></i></button>&nbsp;
                                  </div>
                                </div>

                              </div>

                              <button class="btn btn-success" (click)="addoption(r,s)"><i class="fas fa-plus"></i> {{
                                'rulesets.controls.options.new_option' | translate }}</button>
                            </div>
                            <!-- control settings -->
                          </div>
                        </div>
                      </div>
                      <!-- PREVIEW -->
                      <div class="col-sm-12 col-md-6 col-lg-4">
                        <h4>Preview</h4>
                        <div class="border p-2 rounded">

                          <div class="row" *ngIf="s.controltype === 'Label'">
                            <div class="col-md-12" [ngClass]="s.cssclass">
                              <div class="controllabel">{{s.name}}</div>
                              <div class="controlunit">{{s.resultvalue}} <span *ngIf="s.unit">{{s.unit}}</span></div>
                            </div>
                          </div>
                          <div class="row" *ngIf="s.controltype === 'Input'">
                            <div class="col-md-12" [ngClass]="s.cssclass">
                              <div class="controllabel">{{s.name}}</div>
                              <div class="input-group">
                                <input type="text" class="form-control form-control-sm" [(ngModel)]="s.selectedvalue" (keyup)="update(r,s)" />
                                <div class="input-group-append" *ngIf="s.unit">
                                  <span class="input-group-text controlunit">{{s.unit}}</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="row" *ngIf="s.controltype === 'Numeric'">
                            <div class="col-md-12" [ngClass]="s.cssclass">

                              <div class="controllabel">{{s.name}}</div>
                              <div class="input-group">
                                <input type="number" [min]="s.min" [max]="s.max" [(ngModel)]="s.selectedvalue" (change)="update(r,s)" class="form-control form-control-sm" />

                                <div class="input-group-append" *ngIf="s.unit">
                                  <span class="input-group-text controlunit">{{s.unit}}</span>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="row" *ngIf="s.controltype === 'Select'">
                            <div class="col-md-12" [ngClass]="s.cssclass">

                              <div class="controllabel">{{s.name}}</div>
                              <mat-select [(ngModel)]="s.selectedvalue" (change)="update(r,s)">
                                <mat-option *ngFor="let o of s.options" [value]="o.defaultvalue">{{o.label}}
                                </mat-option>
                              </mat-select>
                            </div>

                          </div>
                          <div class="row" *ngIf="s.controltype === 'Radio'">
                            <div class="col-md-12" [ngClass]="s.cssclass">

                              <div class="form-group">
                                <div class="controllabel">{{s.name}}</div>
                                <mat-radio-button class="block" *ngFor="let o of s.options" [name]="s.name" [value]="s.value" (change)="update(r,s,o,$event)">
                                  {{o.label}}
                                </mat-radio-button>

                                <!--<div class="form-check" *ngFor="let o of s.options">
                                  <input type="radio" [name]="s.name" class="form-check-input"
                                         (change)="update(r,s,o,$event); s.selected = o.selectedvalue" />
                                  <label class="form-check-label" for="exampleRadios2">
                                    {{o.label}}
                                  </label>
                                </div>-->



                              </div>
                            </div>
                          </div>

                          <div class="row" *ngIf="s.controltype === 'Checkbox'">
                            <div class="col-md-12" [ngClass]="s.cssclass">

                              <div class=" form-group">
                                <div class="controllabel">{{s.name}}</div>
                                <div *ngFor="let o of s.options">
                                  <mat-checkbox [name]="s.name" [(ngModel)]="o.selectedvalue" (change)="update(r,s)">{{o.label}}</mat-checkbox>
                                </div>

                              </div>
                            </div>

                          </div>


                        </div>
                      </div>
                    </div>
                    <hr>
                    <button mat-raised-button class="btn btn-success mr-2" (click)="saveruleset(r)"><i class="far fa-save"></i> {{ 'additional.actions.save' | translate}}</button>

                    <button class="btn btn-danger" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" mat-raised-button><i class="fas fa-trash"></i> {{ 'additional.actions.delete' |
                      translate}}</button>&nbsp;
                    <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
                      <mat-card style="max-width: 300px">
                        <mat-card-content>
                          <div style="color: red;padding: 10px 0px;"><i class="fa far fa-exclamation-triangle"></i> {{
                            'additional.warnings.delete_permanently' | translate }}</div>
                          <button mat-button routerLink=".">{{ 'additional.actions.cancel' | translate
                            }}</button>&nbsp;
                          <button mat-flat-button color="warn" (click)="deletecontrol(r, index)">{{
                            'additional.actions.delete' | translate }}</button>
                        </mat-card-content>
                      </mat-card>
                    </mde-popover>

                  </mat-expansion-panel>

                </div>
              </div>
              <!-- controls end -->

              <!-- formula builder start -->
              <hr>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-8">
                  <h4>{{ 'rulesets.controls.formula.form-title' | translate }}</h4>
                  <p>{{ 'rulesets.controls.formula.form-desc' | translate }}</p>
                  <div class="mb-2">
                    <span *ngFor="let s of r.controls; let index = index" class="badge bg-brand-one mr-2" (click)="addFormula(r,s.varname)">{{ s.varname }}</span>
                    <span class="badge bg-brand-secondary text-dark mr-2" (click)="addFormula(r,'+')">+</span>
                    <span class="badge bg-brand-secondary text-dark mr-2" (click)="addFormula(r,'-')">-</span>
                    <span class="badge bg-brand-secondary text-dark mr-2" (click)="addFormula(r,'*')">*</span>
                    <span class="badge bg-brand-secondary text-dark mr-2" (click)="addFormula(r,'/')">/</span>
                  </div>
                  <ngx-codemirror (input)="update(r,s)" [(ngModel)]="r.priceformula" [options]="{
                      theme: 'material',
                      inputStyle: 'textarea',
                      autofocus: false,
                      autocorrect: false,
                      spellcheck: false
                    }"></ngx-codemirror>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <h4>{{ 'rulesets.controls.formula.calc-title' | translate }}</h4>
                  <p>{{ 'rulesets.controls.formula.calc-desc' | translate }}</p>
                  <textarea readonly matInput>{{r.pricecalculated }}</textarea>
                </div>
              </div>
              <!-- formula builder end -->

            </div>
          </mat-tab>
          <mat-tab label="{{ 'rulesets.tabs.advanced' | translate }}">
            <p class="pt-3 pb-0">{{ 'ZusaetzlichesCSS' | translate }}</p>
            <!--<textarea rows="15" id="css_editor" matInput [(ngModel)]="r.customcss"
                    (change)="reloadcustomcss(r)"></textarea>-->
            <ngx-codemirror [(ngModel)]="r.customcss" [options]="{
                      theme: 'material',
                      mode: 'css',
                      smartIndent: true,
                      inputStyle: 'textarea',
                      autofocus: false,
                      autocorrect: true,
                      spellcheck: true
                    }" (change)="reloadcustomcss(r)"></ngx-codemirror>
          </mat-tab>
        </mat-tab-group>
        <div class="pt-3">
          <button style="margin-top:10px;margin-right:10px;width:200px;" mat-raised-button class="btn-success" (click)="saveruleset(r)"><i class="far fa-save"></i> {{ 'rulesets.actions.save' | translate }}</button>
          <button mat-raised-button style="width:200px;margin-top:10px;" class="btn-danger" [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click"><i class="far fa-trash"></i>
            {{ 'rulesets.actions.delete' | translate }}</button>
          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
            <mat-card style="max-width: 300px">
              <mat-card-content>
                <div style="color: red;padding: 10px 0px;"><i class="fa far fa-exclamation-triangle"></i>
                  {{ 'rulesets.actions.delete_msg' | translate }}</div>
                <button mat-button routerLink=".">{{ 'rulesets.actions.cancel' | translate }}</button>&nbsp; <button mat-flat-button color="warn" (click)="deleteset(r)">{{ 'rulesets.actions.delete' | translate
                  }}</button>
              </mat-card-content>
            </mat-card>
          </mde-popover>
        </div>
      </mat-expansion-panel>


      <ng-template #template>
        <div class="modal-header">
          <h4 class="modal-title pull-left">New/Edit Control</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="height:400px;">
          <div style="float:left;width:45%">
            <mat-label class="schedulelabel">{{ 'rulesets.details.schedule_description' | translate }}:&nbsp;
            </mat-label><br />
            <mat-form-field style="    height: 38px;width:50px;">
              <!-- <mat-label>Level</mat-label> -->
              <mat-select placeholder="" [(value)]="newschedule.frequency">
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
                <mat-option value="3">3</mat-option>
                <mat-option value="4">4</mat-option>
                <mat-option value="1">5</mat-option>
                <mat-option value="2">6</mat-option>
                <mat-option value="3">7</mat-option>
                <mat-option value="4">8</mat-option>
                <mat-option value="1">9</mat-option>
                <mat-option value="2">10</mat-option>
                <mat-option value="3">11</mat-option>
                <mat-option value="4">12</mat-option>
              </mat-select>
            </mat-form-field>&nbsp;
            <mat-form-field style="    height: 38px;width:80px">
              <!-- <mat-label>Level</mat-label> -->
              <mat-select placeholder="" [(value)]="newschedule.frequencytype">
                <mat-option value="day">day</mat-option>
                <mat-option value="week">week</mat-option>
                <mat-option value="month">month</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </ng-template>
    </div>


  </div>
</section>