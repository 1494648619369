<div class="container">
  <div class="gap10"></div>


  <mat-card>
    <mat-card-content>
      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <h2 style="margin-right:50px;">Users</h2>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>

          </td>

          <td>

          </td>
          <!-- <td>
        <button mat-raised-button>Filter</button>
      </td> -->
          <td>


          </td>
          <td>
            <!-- <button mat-raised-button (click)="openEditDialog(undefined)">new logitem</button> -->
          </td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>


  <div class="gap10"></div>

  <div class="mat-elevation-z2">

    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
      <!-- uid Column -->
      <ng-container matColumnDef="storename">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Store name</mat-header-cell>
        <mat-cell *matCellDef="let row" style="font-weight:bold;">{{row.settings?.storename}}</mat-cell>
      </ng-container>

      <!-- message Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.settings?.firstName}} {{row.settings?.lastName}} </mat-cell>
      </ng-container>

      <!-- source Column -->
      <ng-container matColumnDef="lastLogin">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.lastLogin | date:'short'}}</mat-cell>
      </ng-container>

      <!-- level Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>E-mail</mat-header-cell>
        <mat-cell *matCellDef="let row"><span><a
              [href]="'mailto:'+row.settings?.email">{{row.settings?.email}}</a></span></mat-cell>
      </ng-container>
      <!-- meta Column -->
      <ng-container matColumnDef="registerDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Registration</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.registerDate| date:'short'}}</mat-cell>
      </ng-container>


      <!-- functions Column -->
      <ng-container matColumnDef="functions">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <!-- <button mat-stroked-button class="btn-functions">
            <mat-icon>add_box</mat-icon>Add
          </button> -->
          <button mat-raised-button color="warn" class="btn-functions" [mdePopoverTriggerFor]="appPopover"
            mdePopoverTriggerOn="click">
            <mat-icon>delete</mat-icon>
          </button>
          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
            <mat-card style="max-width: 300px">
              <mat-card-content>
                <a mat-button routerLink=".">cancel</a>
                <button mat-flat-button color="warn" (click)="deleteLog(row.id)">
                  delete
                </button>

              </mat-card-content>
            </mat-card>
          </mde-popover>

          <!-- <button mat-flat-button class="btn-functions" (click)="openEditDialog(row)">
            <mat-icon>library_books</mat-icon>
          </button> -->

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 50, 100]">
    </mat-paginator>
  </div>

</div>