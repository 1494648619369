<div class="container" flexLayout="column">
  <div style="height:10px"></div>

  <div class="content" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxLayoutGap.xs="9px">
    <mat-card fxFlex="50" class="dashmatcard">
      <h3>Dashboard</h3>

      <div><a [routerLink]="['/superadmin/users/']">Users</a></div>
      <div><a [routerLink]="['/superadmin/customers/']">Customers</a></div>
      <div><a [routerLink]="['/superadmin/subscriptions/']">Subscriptions</a></div>
      <div><a [routerLink]="['/superadmin/orders/']">Orders</a></div>
      <div><a [routerLink]="['/superadmin/invoices/']">REO Invoices</a></div>
      <div><a [routerLink]="['/superadmin/logs/']">Logs</a></div>
      <hr />
      <!-- <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="single" [gradient]="gradient" [xAxis]="showXAxis"
        [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
      </ngx-charts-bar-vertical> -->
      <h3>Last Orders</h3>
      <ul>
        <li *ngFor="let o of lastorders | async">
          <span style="color:gray;">{{ o.executiontime | date: "short" }}</span
          ><span style="width:150px;display:inline-block;padding-left:15px;">{{ o.store }}</span> <span style="font-weight: bold;width:80px;display:inline-block">{{ o.paysum | number: "1.2-2" }}€</span> {{ o.products?.name }}
        </li>
      </ul>
      <div><a [routerLink]="['/superadmin/orders/']">All Orders</a></div>
    </mat-card>

    <mat-card class="dashmatcard" fxFlex="50" class="sec3" fxFlex.xs="15">
      <h3>Last Subscriptions</h3>
      <ul>
        <li *ngFor="let o of lastsubs | async">
          <span style="color:gray;">{{ o.createdDate | date: "short" }}</span
          ><span style="width:150px;display:inline-block;padding-left:15px;">{{ o.store }}</span> <span style="font-weight: bold;width:80px;display:inline-block">{{ o.amount | number: "1.2-2" }}€</span> {{ o.subscriptionName }}
        </li>
      </ul>
      <div><a [routerLink]="['/superadmin/subscriptions/']">All Subscriptions</a></div>
    </mat-card>
  </div>

  <div style="height:10px"></div>

  <!-- <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="center stretch" fxLayoutGap="10px"
    fxLayoutGap.xs="9px">


    <mat-card class="dashmatcard" fxFlex="25" class="sec1" fxFlex.xs="55">
      <h3>24 hours of OEE</h3>
      dsffdssd<br> dsffdssd<br>dsffdssd<br>dsffdssd<br>dsffdssd<br>dsffdssd<br>
    </mat-card>


    <mat-card class="dashmatcard" fxFlex="20" class="sec2">
      <h3>24 hours of OEE</h3>
      dsffdssddsffdssd<br>dsffdssd<br>
    </mat-card>

     <div fxFlex="55" class="sec3" fxFlex.xs="15" fxLayoutAlign="none stretch ">

    <mat-card class="dashmatcard" fxFlex="55" class="sec3" fxFlex.xs="15">
      <h3>Statistik</h3>
     <ngx-charts-sparkline [view]="[100,50]" class="chart-container" [results]="sparklineData" [animations]="true">
      </ngx-charts-sparkline>
    </mat-card>


  </div> -->

  <div style="height:10px"></div>
</div>
