import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-logdetail",
  templateUrl: "./logdetail.component.html",
  styleUrls: ["./logdetail.component.css"]
})
export class LogdetailComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<LogdetailComponent>) {}

  closeDialog() {
    this.dialogRef.close("Pizza!");
  }
  ngOnInit() {}
}
