<h3>{{data.id}}</h3>
data:
<!-- <div>
  {{data|json}} {{data|json}} {{data|json}}
</div> -->


<!-- datetime Column -->
<mat-form-field class="example-full-width">
  <input matInput [ngModel]="data.datetime" placeholder="-">
</mat-form-field>
<br>
<!-- message Column -->
<mat-form-field class="example-full-width">
  <input matInput [ngModel]="data.message" placeholder="message">
</mat-form-field>
<br>
<!-- source Column -->
<mat-form-field class="example-full-width">
  <input matInput [ngModel]="data.source" placeholder="source">
</mat-form-field>
<br>
<!-- level Column -->
<mat-select [ngModel]="data.level" placeholder="level" style="width:200px">
  <mat-option value="option">debug</mat-option>
  <mat-option value="option">error</mat-option>
  <mat-option value="option">info</mat-option>
  <mat-option value="option">all</mat-option>
</mat-select>
<br>
<!-- meta Column -->
<mat-form-field class="example-full-width">
  <input matInput [ngModel]="data.meta" placeholder="meta">
</mat-form-field>
<hr>

<div style="float:right;">

  <button mat-raised-button (click)="closeDialog()">cancel</button>
  <button mat-raised-button color="primary" (click)="closeDialog()">save</button>

</div>
