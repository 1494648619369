// TODO: options mit label + Wert, css class pro ruleset, Preis immer unten, Validierungsregeln, variablennamen => slugs
// css https://stackoverflow.com/questions/47350066/is-it-possible-to-apply-dynamic-style-as-string-in-angular
//  https://shekhargulati.com/2018/01/16/dynamically-loading-css-in-angular-5-application/
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, TemplateRef, Inject, ViewChild } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";
import { RulesetdetailComponent } from "../_dialogs/rulesetdetail/rulesetdetail.component";

import { AngularFirestore } from "@angular/fire/firestore";
import { LoggerService } from "../logger.service";
import { map, throwIfEmpty } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AngularFireAuth } from "@angular/fire/auth";
import { Ruleset } from "./../../model/model";
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Upload } from '../utils/upload';
import * as firebase from 'firebase';
import { NgForOf } from "@angular/common";


@Component({
  selector: "app-rulesets",
  templateUrl: "./rulesets.component.html",
  styleUrls: ["./rulesets.component.css"]
})
export class RulesetsComponent implements OnInit {
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  SERVER = "https://prodopt.herokuapp.com"; // "https://reoapp.herokuapp.com"; // "http://localhost:3000"; //// "http://localhost:3000"; //
  identifyer = (index: number, item: any) => item.name;
  cmbproduct: any;
  name: any;
  animal: any;
  user: any;
  sets: Ruleset[];
  ruleset: Ruleset;
  shopproducts: any;
  modalRef: BsModalRef;
  loading = false;
  selectedpids: any;
  prodForm: FormGroup;
  ShowFilter = false; selectedIndex = 0;
  limitSelection = false;
  selectedItems = [];
  userproducts: any;
  test = [];
  dropdownSettings: any = {};

  public newcontrol = { frequency: 1, frequencytype: 1, cycles: 3, calctype: 1 };
  cssUrl: string;

  constructor(public sanitizer: DomSanitizer, public afs: AngularFirestore, public auth: AngularFireAuth, public http: HttpClient, private _snackBar: MatSnackBar, ls: LoggerService, public dialog: MatDialog, private modalService: BsModalService, private fb: FormBuilder) {
    this.cmbproduct = "p1";
    console.log("#auth ", auth);

    this.dropdownSettings = {
      "singleSelection": false,
      "defaultOpen": false,
      "idField": "item_id",
      "textField": "item_text",
      "selectAllText": "Select All",
      "unSelectAllText": "UnSelect All",
      "enableCheckAll": false,
      "itemsShowLimit": 100,
      "allowSearchFilter": false
    };

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {

    this.afs.collection("rulesets", ref => ref.where("uid", "==", this.auth.auth.currentUser.uid).orderBy("createdate", "asc")).valueChanges({ idField: "id" }).subscribe(x => {
      console.log("rulesets subscription", x);

      console.log(this.tabGroup);
      this.sets = <any>x;

      for (var i = 0; i < this.sets.length; i++) {
        //      if(!)
        //          this.sets[i].selectedIndex = 0;
        this.update(this.sets[i], null);
        this.reloadcustomcss(this.sets[i]);
      }

      this.afs.doc("users/" + this.auth.auth.currentUser.uid).valueChanges().subscribe(x => {
        this.user = x;
        console.log("user", this.user);
        this.getShopifyProducts();
        this.initMultipleSelect(this.shopproducts);
      });


      // this.selectedpids = [];
      // for (var j = 0; j < this.sets.length; j++) {
      //   if (this.sets[j].products) {
      //     for (var i = 0; i < this.sets[j].products.length; i++) {
      //       this.selectedpids.push((<any>this.sets[j].products[i]).id);
      //     }
      //   }
      // }

    });

    const randomIntegerInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
    const n = randomIntegerInRange(1, 100) % 2;
    this.cssUrl = n % 2 === 0 ? '/assets/styles1.css' : '/assets/styles2.css';
    // in apphtml <link rel="stylesheet" [href]='sanitizer.bypassSecurityTrustResourceUrl(cssUrl)'>


  }
  tabchange(set, e) {
    console.log('tabchange', e)
    set.selectedIndex = e;
  }

  /* FORMULA BUILDER */

  addFormula(ruleset, text) {
    ruleset.priceformula += text + " ";
  }

  /* Control Drop */

  log(msg) {
    console.log('LOG > ' + msg);
  }

  allowDrag(ev) {
    ev.preventDefault();
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log('drop ', event)
    // moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  }

  /* DROPDOWN TEST FUNCTIONS */
  onItemSelect(item: any) {
    console.log('onItemSelect', item, "r.selectedProdukts", this.ruleset.selectedProducts, "userproducts", this.userproducts);

  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onClick(value: any) {
    console.log(value);
  }

  expansionStates = {};
  panelClosed(panel) {
    this.expansionStates[panel] = false;
  }


  panelOpened(panel) {
    this.expansionStates[panel] = true;
  }
  modifyContent(name: any) {
    const x = this.sets.find(c => c.name === name);
    //  x.content += 'abc';

    // this line mimics the ngrx creation of a new state-object
    // outcomment this and the bug won't occure
    this.sets = this.sets.map(element => { return { ...element } });
  }
  getShopifyProducts() {
    // tslint:disable-next-line:max-line-length
    console.log("getShopifyProducts() user:", this.user); // /, httpOptions
    this.http.get(this.SERVER + "/getproducts?shop=" + this.user?.settings?.storeurl).subscribe(data => {//+ ).subscribe(data => {   prodopt.myshopify.com
      this.shopproducts = (<any>data).products;
      console.log('products ', this.shopproducts);
      this.initMultipleSelect(this.shopproducts);

      this.selectedpids = [];
      for (var j = 0; j < this.sets.length; j++) {
        if (this.sets[j].products) {
          for (var i = 0; i < this.sets[j].products.length; i++) {
            this.selectedpids.push((<any>this.sets[j].products[i]).id);
          }
        }
      }
      //console.log('selectedpids', this.selectedpids);

    });
  }

  initMultipleSelect(input) {

    this.userproducts = [];
    for (let entry of input) {
      let subarray = { item_id: entry.id, item_text: entry.title };
      this.userproducts.push(subarray);
    }
    console.log('userproducts', this.userproducts);
    //this.selectedItems = [];

  }

  async addruleset(name) {
    //    let newid = this.afs.createId();
    let newset = { name: name, uid: this.auth.auth.currentUser.uid, createdate: new Date().getTime() };
    //    this.afs.collection("rulesets").doc(newid).set(newset);
    await this.afs.collection("rulesets").add(newset);
    this.ngOnInit();
  }

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker'
  ];

  addproduct(ruleset) {
    // bereits gesetzte Produkte filtern
    let newproduct = this.shopproducts[0]; // {  }; // { name: newid, uid: this.auth.auth.currentUser.uid };
    if (!ruleset.products) {
      ruleset.products = [];
    }
    ruleset.products.push({});
    this.afs.collection("rulesets").doc(ruleset.id).update(ruleset);
  }


  dochange(event, ruleset, p, k) {
    console.log("dochange", event, p.id, k);
    // console.log("sets ", this.sets);
    // for (var i = this.shopproducts.length - 1; i >= 0; i--) {
    var alreadyset = false;


    //  p.id = event.value;
    console.log('alreadyset', alreadyset);
    if (!alreadyset)
      for (var i = 0; i < this.shopproducts.length; i++) {
        if (this.shopproducts[i].id === event.value) {
          console.log("setproduct", this.shopproducts[i]);
          ruleset.products[k] = this.shopproducts[i];
          break;
        }
      }

    this.selectedpids = [];
    for (var j = 0; j < this.sets.length; j++) {
      if (this.sets[j].products) {
        for (var i = 0; i < this.sets[j].products.length; i++) {
          this.selectedpids.push((<any>this.sets[j].products[i]).id);
        }
      }
    }
    console.log('selectedpids', this.selectedpids)

  }

  deleteset(r) {
    this.afs.doc("rulesets/" + r.id).delete();

    this.ngOnInit();
  }
  deletecontrol(r, s) {
    console.log('deletecontrol', r, s);
    r.controls.splice(s, 1);
  }
  deleteproduct(ruleset, i) {
    console.log('deleteproduct', ruleset, i);
    ruleset.products.splice(i, 1);
    this.afs.collection("rulesets").doc(ruleset.id).update(ruleset);
  }


  saveruleset(ruleset: Ruleset) {
    console.log("saverauleset", ruleset);

    // if (!ruleset.controls) {
    //   this._snackBar.open("Missing a control! Add a control first.", "", { duration: 3000 });
    //   return;
    // }
    // if (!ruleset.selectedProducts) {
    //   this._snackBar.open("Missing a product! Add a product first.", "", { duration: 3000 });
    //   return;
    // }

    for (var i = 0; i < ruleset.controls.length; i++)
      ruleset.controls[i].cycles = ruleset.cycles;

    console.log("save ruleset", ruleset);

    //    this.afs.collection("rulesets").doc(ruleset.id).update(JSON.parse(JSON.stringify(ruleset)));

    this.afs.collection("rulesets").doc(ruleset.id).update(JSON.parse(JSON.stringify(ruleset)));
    this.setMetaFields(ruleset);
    this._snackBar.open("Changes saved.", "", { duration: 3000 });

  }

  openDialog() {
    const dialogRef = this.dialog.open(RulesetdetailComponent, { width: "450px", data: { name: this.name }, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      console.log("The dialog was closed");
      if (result !== "") {
        this.addruleset(result);
        console.log(result);
      }
    });
  }
  openEditDialog(logitem) {
    if (!logitem) {
      logitem = {};
    }

    const dialogRef = this.dialog.open(RulesetdetailComponent, { data: logitem });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.afs.collection("rulesets").add(result);
    });
  }

  public setMetaFields(ruleset) {

    // für jedes Produkt metafields setzen
    console.log("setmetafield rs", ruleset, "user", this.user);

    if (ruleset.selectedProducts)
      ruleset.selectedProducts.forEach(product => {
        console.log("setMetaField", product);

        this.http.get(this.SERVER + "/setproductmetafields?shop=" + this.user.settings.storeurl + "&productid=" + product.item_id + "&rulesetid=" + ruleset.id)
          .subscribe(data => {
            console.log("shopify metafields set result ", data);
          });
      });

  }

  public addControl(ruleset: Ruleset) {
    if (!ruleset.controls) {
      ruleset.controls = [];
    }
    var newcontrol = {
      uid: this.auth.auth.currentUser.uid,
      rulesetid: ruleset.id,
      name: "Control" + (ruleset.controls.length + 1),
      varname: "control" + (ruleset.controls.length + 1),
      controltype: "Label"
    };

    ruleset.controls.push(newcontrol);

    this.setMetaFields(ruleset);

    console.log("new control", newcontrol);
  }

  public addoption(r, s) {
    var count = 0;
    if (s.options) {

      count = s.options.length;
    }
    else {
      s.options = [];
    }
    count++;

    s.options.push({ defaultvalue: "Option " + count });

    s.oselected = s.options[0].defaultvalue;
  }
  public removeoption(s, oi) {
    s.options.splice(oi, 1);

  }

  defaultchange(s) {
    s.selectedvalue = s.defaultvalue;
  }
  public update(ruleset: Ruleset, control, o = null, event = null) {
    console.log(ruleset, control, o, event);
    if (o)// radio wert setzen w
    {
      control.selectedvalue = parseFloat(o.defaultvalue);

    }

    for (let i = 0; i < ruleset.controls?.length; i++) {
      try {
        console.log('update', ruleset.controls[i])

        if (ruleset.controls[i].controltype === 'Checkbox') {
          try {
            // checkboxen vars einzeln setzen
            var sum = 0;
            for (var j = 0; j < ruleset.controls[i].options.length; j++)
              try {
                // wert setzen wenn selected, sonst 0
                if (ruleset.controls[i].options[j].selectedvalue) {
                  sum += parseFloat(ruleset.controls[i].options[j].defaultvalue);
                  (window as any)[ruleset.controls[i].varname + "_" + (j + 1)] = parseFloat(ruleset.controls[i].options[j].defaultvalue);
                  (window as any)[ruleset.controls[i].varname] = sum;
                }
                else {

                  (window as any)[ruleset.controls[i].varname + "_" + (j + 1)] = 0;
                }
              }
              catch (ex) { }

            if (ruleset.controls[i].varname && ruleset.controls[i].controltype !== 'Checkbox')
              (window as any)[ruleset.controls[i].varname] = parseFloat(ruleset.controls[i].selectedvalue);
          } catch (err) {
            if (ruleset.controls[i].varname && ruleset.controls[i].controltype !== 'Checkbox')
              (window as any)[ruleset.controls[i].varname] = ruleset.controls[i].selectedtvalue;
          }
        }
        if (ruleset.controls[i].controltype === 'Radio') {
          try {
            var radios = ruleset.controls[i].options;
          }
          catch (err) {

          }
        }

        if (ruleset.controls[i].varname && ruleset.controls[i].controltype !== 'Checkbox')
          (window as any)[ruleset.controls[i].varname] = parseFloat(ruleset.controls[i].selectedvalue);
      } catch (err) {
        if (ruleset.controls[i].varname && ruleset.controls[i].controltype !== 'Checkbox')
          (window as any)[ruleset.controls[i].varname] = ruleset.controls[i].selectedtvalue;
      }
    }
    console.log('update2')

    for (let i = 0; i < ruleset.controls?.length; i++) {
      try {

        if (ruleset.controls[i].controltype === 'Checkbox') {

        }
        console.log('update', ruleset.controls[i])

        let evaluated = eval('' + ruleset.controls[i].selectedvalue);
        ruleset.controls[i].resultvalue = evaluated;

      } catch (err) {
        console.log(err)
      }

    }

    ruleset.pricecalculated = eval(ruleset.priceformula);
  }


  reloadcustomcss(ruleset: Ruleset) {
    //var css = '.form-control{border-color:red;}';
    if (document.getElementById(ruleset.id))
      document.getElementById(ruleset.id).remove();

    // Build your `<link>` dynamically
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = ruleset.id;
    link.href = 'data:text/css;charset=UTF-8,' + encodeURIComponent(ruleset.customcss);
    document.getElementsByTagName('head')[0].appendChild(link);

  }





  disable(cssId) {
    (document as any).getElementById(cssId).disabled = true;
  }



}

