import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
export interface DialogData {
  name: string;
}
@Component({
  selector: "app-rulesetdetail",
  templateUrl: "./rulesetdetail.component.html",
  styleUrls: ["./rulesetdetail.component.css"]
})
export class RulesetdetailComponent {
  constructor(public dialogRef: MatDialogRef<RulesetdetailComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
