import { Component, OnInit, Input } from "@angular/core";
import { LoggerService } from "../logger.service";
import { Breakpoints, BreakpointState, BreakpointObserver } from "@angular/cdk/layout";
import { map } from "rxjs/operators";
import { ViewEncapsulation, ChangeDetectionStrategy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: "app-dashboard",
  templateUrl: "./new2.dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  orders: any;
  customers: any;
  subscriptions: any;

  SalesVolumeData: ChartDataSets[] = [
    { data: [7560, 7450, 7889, 7398, 7503, 8029, 8534, 8245], label: '' },
  ];

  ActiveSubscriptions: ChartDataSets[] = [
    { data: [349, 463, 690, 832, 1245, 1302, 1378, 1478], label: '' },
  ];

  RegisteredCustomers: ChartDataSets[] = [
    { data: [346, 402, 655, 803, 1089, 1178, 1264, 1282], label: '' },
  ];

  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];

  lineChartOptions = {
    responsive: true,
    legend: {
      display: false,
      fontFamily: "bold"
    },
    tooltips: {
      fontFamily: "medium",
      backgroundColor: 'rgba(0,0,0,0.5)',
      titleFontColor: '#ffffff',
      bodyFontColor: '#ffffff',
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    scales: {
      yAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(29,140,248,0.0)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#0C0F1A",
          fontFamily: "medium"
        }
      }],
      xAxes: [{
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: 'rgba(255,252,249,0.025)',
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#0C0F1A",
          fontFamily: "medium"
        }
      }]
    }
  };

  lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(92, 119, 254,.5)',
      borderColor: '#728AFF',
      borderWidth: 2,
      borderDash: [],
      borderDashOffset: 0.0,
      pointBackgroundColor: '#728AFF',
      pointBorderColor: 'rgba(255,255,255,0)',
      pointHoverBackgroundColor: '#728AFF',
      pointBorderWidth: 20,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 15,
      pointRadius: 4,
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  constructor(logger: LoggerService, private afAuth: AngularFireAuth, private breakpointObserver: BreakpointObserver, public afs: AngularFirestore) { }

  async ngOnInit() {


    var up = await this.afs.doc("users/" + this.afAuth.auth.currentUser.uid).get().toPromise();
    let u = up.data();

    this.customers = this.afs.collection("customers", ref => ref.orderBy("createtime", "desc").where("store", "==", u.settings.storename).limit(5)).valueChanges();

    this.orders = this.afs.collection("orders", ref =>
      ref.where("store", "==", u.settings.storename).where("state", "==", "paid").orderBy("datetime", "desc").limit(10)
    ).valueChanges();

    this.subscriptions = this.afs.collection("reoSubscriptions", ref =>
      ref.where("store", "==", u.settings.storename).orderBy("createdDate", "desc").limit(10)
    ).valueChanges();
  }
}
