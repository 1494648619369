import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'paymentstatebadge'
})
export class PaymentstatebadgePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value: string, ...args: unknown[]): unknown {
    var r = '<div class=\"test\" style="float:left;font-size:10px;padding:2px 8px;background-color:darkgray; color:white; border-radius:3px;">' + value + '</div>';
    if (value === 'paid')
      r = '<div class=\"test\" style="float:left;font-size:10px;padding:2px 8px;background-color:green; color:white; border-radius:3px;">' + value + '</div>';
    return this.sanitized.bypassSecurityTrustHtml(r);
  }

}
