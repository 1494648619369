import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { LogComponent } from "./superadmin/log/log.component";
import { SettingsComponent } from "./settings/settings.component";
import { UsersComponent } from "./superadmin/users/users.component";
import { InvoicesComponent } from "./invoices/invoices.component";

//import { RedirectGuard } from "./login/redirectguard";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import "rxjs/add/operator/do";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";
import { RulesetsComponent } from "./rulesets/rulesets.component";
import { SuperadmindashboardComponent } from "./superadmin/superadmindashboard/superadmindashboard.component";
import { NodeLogComponent } from './superadmin/nodelog/nodelog.component';
import { ProductpageComponent } from './productpage/productpage.component';

// siehe auch https://itnext.io/part-2-complete-step-by-step-firebase-authentication-in-angular-2-25d284102632
@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private authService: AngularFireAuth, private router: Router) { }
  canActivate(): Observable<boolean> {
    return this.authService.authState
      .take(1)
      .map(authState => !!authState)
      .do(authenticated => {
        if (!authenticated) {
          this.router.navigate(["/login"]);
        }
      });
  }
}

const routes: Routes = [
  // {
  //   path: "",
  //   component: LoginComponent,
  //   canActivate: [RedirectGuard]
  // },

  // https://reoapp-20f8b.firebaseapp.com/login?hmac=4a00030e2d5ff2a5415c1e5ad3adea34a06721e9b0ed84fde8866fd5a1dca352&shop=recurring-order-demo.myshopify.com&timestamp=1564661143
  { path: "", redirectTo: "login", pathMatch: "full" },

  // { path: 'home', component: Home },
  // { path: '', component: DashboardComponent },
  // { path: '**', component: DashboardComponent },


  // { path: "checkout/:shop/:carttoken", component: CheckoutComponent }, //alt
  // { path: "begincheckout/:shop/:carttoken", component: CheckoutComponent }, //alt
  { path: "dashboard", component: DashboardComponent, canActivate: [RedirectGuard] },
  { path: "login", component: LoginComponent },
  { path: "invoices", component: InvoicesComponent, canActivate: [RedirectGuard] },
  { path: "settings", component: SettingsComponent, canActivate: [RedirectGuard] },
  { path: "rulesets", component: RulesetsComponent, canActivate: [RedirectGuard] },

  { path: "product", component: ProductpageComponent },


  { path: "superadmin", component: LoginComponent },
  { path: "superadmin/users", component: UsersComponent, canActivate: [RedirectGuard] },
  { path: "superadmin/dashboard", component: SuperadmindashboardComponent, canActivate: [RedirectGuard] },
  { path: "superadmin/logs", component: LogComponent, canActivate: [RedirectGuard] },
  { path: "superadmin/nodelogs", component: NodeLogComponent, canActivate: [RedirectGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
