<section>
  <h2>{{ 'settings.site_title' | translate }}</h2>
  <mat-card class="mb-3">
    <h5>{{ 'settings.desc_title' | translate }}</h5>
    <p>{{ 'settings.description' | translate }}</p>
  </mat-card>
  <form [formGroup]="contactForm">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h4>{{ 'settings.general_title' | translate }}</h4>
        <mat-expansion-panel class="mb-3">
          <mat-expansion-panel-header>
            <mat-panel-title><strong>{{ 'settings.my_account.title' | translate }}</strong></mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column">
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex> <input matInput type="text" placeholder="first name" (change)="save()"
                  formControlName="firstName" /> </mat-form-field>
              <mat-form-field fxFlex> <input matInput type="text" placeholder="last name" (change)="save()"
                  formControlName="lastName" />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex> <input matInput type="text" placeholder="Street" (change)="save()"
                  formControlName="street" />
              </mat-form-field>
              <mat-form-field fxFlex> <input matInput type="number" placeholder="Street No" (change)="save()"
                  formControlName="streetno" /> </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex> <input matInput type="text" placeholder="City" (change)="save()"
                  formControlName="city" />
              </mat-form-field>
              <mat-form-field fxFlex> <input matInput type="number" placeholder="Zip" (change)="save()"
                  formControlName="zip" />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-form-field fxFlex="50%"> <input matInput type="text" placeholder="country" (change)="save()"
                  formControlName="country" /> </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>

      </div>
      <div class="col-sm-12 col-md-6">



      </div>
    </div>

  </form>
</section>