import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {
  PrettyJsonModule
} from 'angular2-prettyjson';
import { ModalModule } from "ngx-bootstrap/modal";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LogComponent } from "./superadmin/log/log.component";
import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./superadmin/admin.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AppRoutingModule, RedirectGuard } from "./app-routing.module";
import { RouterModule } from "@angular/router";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService
} from "@angular/fire/analytics";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { SettingsComponent } from "./settings/settings.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MomentModule } from "ngx-moment";
import { DebuglevelPipe } from "./debuglevel.pipe";

// https://github.com/material-extended/mde/blob/master/README.md
import { MdePopoverModule } from "@material-extended/mde";

import { UsersComponent } from "./superadmin/users/users.component";
import { HttpModule } from "@angular/http";

import { InvoicesComponent } from "./invoices/invoices.component";
import { RulesetsComponent } from "./rulesets/rulesets.component";

// import { SparklineComponent } from "./sparkline.component";
import { MatRadioModule } from "@angular/material/radio";
import { ColorPickerModule } from "ngx-color-picker";
import { RulesetdetailComponent } from "./_dialogs/rulesetdetail/rulesetdetail.component";
import { MatStepperModule } from "@angular/material/stepper";
import { SuperadmindashboardComponent } from "./superadmin/superadmindashboard/superadmindashboard.component";

import { LOCALE_ID } from "@angular/core";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { registerLocaleData, JsonPipe } from "@angular/common";
import { LogmessagePipe } from "./logmessage.pipe";

import { LogdetailComponent } from "./_dialogs/logdetail/logdetail.component";
import { UploadwidgetComponent } from './uploadwidget/uploadwidget.component';
import { PaymentstatebadgePipe } from './paymentstatebadge.pipe';
import { NodeLogComponent } from './superadmin/nodelog/nodelog.component';
import { MatExpansionModule } from "@angular/material/expansion";
//TRANSLATE
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
//CHARTJS
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormevalPipe } from './rulesets/formeval.pipe';
import { ProductpageComponent } from './productpage/productpage.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatCheckboxModule} from '@angular/material/checkbox';


registerLocaleData(localeDe, localeDeExtra);

console.log('env app', environment)

@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [
    AppComponent,
    LogComponent,
    NodeLogComponent,
    LoginComponent,
    AdminComponent,
    DashboardComponent,
    SettingsComponent,
    DebuglevelPipe,
    LogmessagePipe,
    LogdetailComponent,
    InvoicesComponent,

    UsersComponent,
    RulesetsComponent,
    RulesetdetailComponent,
    SuperadmindashboardComponent,
    LogmessagePipe,
    UploadwidgetComponent,
    PaymentstatebadgePipe,
    FormevalPipe,
    ProductpageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatTableModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatRadioModule,
    MomentModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatCardModule,
    ReactiveFormsModule,
    MdePopoverModule,
    MatDialogModule,
    MatGridListModule,
    MatTabsModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    //   FlexLayoutModule,
    ColorPickerModule,
    PrettyJsonModule,
    HttpModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatPaginatorModule,
    MatSortModule,
    ModalModule.forRoot(),
    AngularFireAnalyticsModule,
    MatExpansionModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ChartsModule,
    NgbModule,
    CodemirrorModule,
    NgMultiSelectDropDownModule.forRoot(),
    DragDropModule,
    MatCheckboxModule
  ],
  entryComponents: [
    RulesetdetailComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de" },
    RedirectGuard,
    ScreenTrackingService,
    UserTrackingService
  ], //  { provide: JsonPipe, useClass: SafeJsonPipe },
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
