import { Component, OnInit, ViewChild, Injectable, Inject } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { map } from "rxjs/operators";
import { Observable, of as observableOf, merge } from "rxjs";

import { LoggerService } from "src/app/logger.service";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.css"]
})
export class InvoicesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  filter: string;
  //  dataSource: InfotableDataSource;
  dataSource: MatTableDataSource<InvoiceItem>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ["datetime", "customer", "amount", "functions"];

  public options: any;

  entries: any;
  constructor(public afs: AngularFirestore, ls: LoggerService, public dialog: MatDialog) {
    this.dataSource = new InvoiceDataSource();
    //    this.dataSource.data = [{ firstname: null, message: '<img src="/assets/ajax-loader.gif" />', source: "", level: "", meta: null }];
    this.options = "option";
  }


  ngOnInit() {
    //  this.dataSource = new InfotableDataSource(this.afs, this.paginator, this.sort);

    this.afs
      .collection("invoices") //, ref => ref.where("deleted", "==", null).where("store", "==", 'reostore1'))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data as {} };
          });
        })
      )
      .subscribe(x => {
        this.dataSource.data = <any>x;
        console.log("x", x);

        this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() { }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  deleteCustomer(id) {
    console.log("delete " + id);
    //    this.afs.doc("/customers/" + id).delete();
    this.afs.doc("/customers/" + id).update({ deleted: true });
  }
}

// TODO: Replace this with your own data model type
export interface InvoiceItem {
  datetime: any;
  amount: number;
  customer: any;
}

export class InvoiceDataSource extends MatTableDataSource<InvoiceItem> {
  data: InvoiceItem[];

  constructor() {
    super();
  }

  disconnect() { }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
