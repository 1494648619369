import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-superadmindashboard",
  templateUrl: "./superadmindashboard.component.html",
  styleUrls: ["./superadmindashboard.component.css"]
})
export class SuperadmindashboardComponent implements OnInit {
  public lastorders: any;
  public lastsubs: any;
  constructor(public afs: AngularFirestore) {
    this.lastorders = afs.collection("orders", ref => ref.orderBy("executiontime").limit(15)).valueChanges();
    this.lastsubs = afs.collection("reoSubscriptions", ref => ref.orderBy("createdDate").limit(10)).valueChanges();
  }

  ngOnInit() {}
}
