import { Component, Renderer2 } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router, ActivatedRoute } from "@angular/router";
import { fadeAnimation } from "./animations";
import { TranslateService } from '@ngx-translate/core';
import {BreakpointObserver} from "@angular/cdk/layout";
import {AngularFirestore} from "@angular/fire/firestore";

@Component({
  selector: "app-root",
  templateUrl: "./new.app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [fadeAnimation]
})
export class AppComponent {
  title = "The Platform";
  events: string[] = [];
  opened: boolean = false; // sidenav
  shouldRun: boolean = true;
  toggle: boolean = true;
  UserLanguage = "en";
  //   constructor( ) {
  constructor(public afAuth: AngularFireAuth, public router: Router, public route: ActivatedRoute, public renderer: Renderer2, private translate: TranslateService) {
    // console.log("route ", this.route.snapshot);
    // console.log("router", this.router);
    if (localStorage.getItem("lang") === null) {
      localStorage.setItem("lang","en");
    }
    this.translate.use(localStorage.getItem("lang"));
    let id = this.route.snapshot.paramMap.get("id");
  }

  useLanguage(language: string) {
    localStorage.setItem("lang",language);
    this.translate.use(language);
  }



}
