import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-productpage',
  templateUrl: './productpage.component.html',
  styleUrls: ['./productpage.component.scss']
})
export class ProductpageComponent implements OnInit {
  SERVER = "https://prodopt.herokuapp.com";
  pid: string;
  constructor(route: ActivatedRoute, public http: HttpClient) {

    route.queryParams.subscribe((x) => {
      this.pid = x.pid;
    });

  }

  ngOnInit(): void {
  }

  testVariant() {

    var data = { productid: this.pid, test: "test1" }

    this.http.post(this.SERVER + "/getproducts?productid=" + this.pid, data).subscribe(data => {
      console.log("testvarint result ", data);
    });

  }
}
