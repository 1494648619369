<div class="container">
  <h2>TODO: Invoices</h2>

  <hr />

  <div class="mat-elevation-z2">
    <mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
      <ng-container matColumnDef="customer">
        <mat-header-cell *matHeaderCellDef mat-sort-header>customer</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.customer }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="datetime">
        <mat-header-cell *matHeaderCellDef mat-sort-header>datetime</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.datetime }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>amount</mat-header-cell>
        <mat-cell *matCellDef="let row"><span [innerHTML]="row.amount"></span></mat-cell>
      </ng-container>



      <ng-container matColumnDef="meta">
        <mat-header-cell *matHeaderCellDef mat-sort-header>meta</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button *ngIf="row.meta != {} && row.meta !== '{}' && row.meta != undefined" mat-icon-button
            [matMenuTriggerFor]="menu" matTooltip="PopOver Me!">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <span mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()">
              <!-- popover content begin -->
              <span [innerHTML]="row.meta | json"></span>
              <!-- popover content end  -->
            </span>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <!-- functions Column -->
      <ng-container matColumnDef="functions">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-flat-button color="warn" class="btn-function mat-small" [mdePopoverTriggerFor]="appPopover"
            mdePopoverTriggerOn="click">
            <mat-icon>delete</mat-icon>
          </button>
          <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
            <mat-card style="max-width: 300px">
              <mat-card-content> <a mat-button routerLink=".">cancel</a> <button mat-flat-button color="warn"
                  (click)="deleteCustomer(row.id)">delete</button> </mat-card-content>
            </mat-card>
          </mde-popover>

          <!-- <button mat-flat-button class="btn-functions"  (click)="openEditDialog(row)"><mat-icon>library_books</mat-icon></button> -->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator #paginator [length]="dataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 50, 100]"> </mat-paginator>
  </div>
</div>

<!-- <button (click)="addCustomer()">Add Customer</button> -->