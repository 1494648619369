import {
  Component,
  OnInit,
  ViewChild,
  Injectable,
  Inject
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { map } from "rxjs/operators";
import { Observable, of as observableOf, merge } from "rxjs";
// import { LogdetailComponent } from "../../_dialogs/logdetail/logdetail.component";
import { LoggerService } from "src/app/logger.service";

@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"]
})
export class LogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  filter: string;
  //  dataSource: InfotableDataSource;
  dataSource: MatTableDataSource<LogtableItem>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    "datetime",
    "message",
    "meta",
    "source",
    "level",
    "functions"
  ];

  public options: any;

  entries: any;
  constructor(
    public afs: AngularFirestore,
    ls: LoggerService,
    public dialog: MatDialog
  ) {
    this.dataSource = new InfotableDataSource();
    this.dataSource.data = [];
    this.options = "option";
  }
  getbillingagreementslogs(ba) {
    this.afs
      .collection("ppbillingagreements/" + ba.id + "/logs")
      .valueChanges()
      .subscribe(x => {
        if (x) {
          console.log(x);
          ba.logs = x;
        }
      });
  }

  ngOnInit() {
    //  this.dataSource = new InfotableDataSource(this.afs, this.paginator, this.sort);

    this.afs
      .collection("logs", r => r.orderBy("datetime", "desc").limit(1000))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data as {} };
          });
        })
      )
      .subscribe(x => {
        this.dataSource.data = <any>x;
        // this.dataSource.filterPredicate = (data: LogtableItem, filter: string) => {
        //   filter = filter.trim(); // Remove whitespace
        //   filter = filter.toLowerCase();
        //   return data.message.includes(filter);
        // };

        this.dataSource.filter = this.filter;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() { }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  deleteLog(id) {
    console.log("delete " + id);
    this.afs.doc("/logs/" + id).delete();
  }
}

// TODO: Replace this with your own data model type
export interface LogtableItem {
  datetime: any;
  message: string;
  source: string;
  level: string;
  meta: any;
}

export class InfotableDataSource extends MatTableDataSource<LogtableItem> {
  data: LogtableItem[];

  constructor() {
    super();
  }

  disconnect() { }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
