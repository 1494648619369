import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "debuglevel"
})
export class DebuglevelPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    if (value === "error") {
      return this.sanitizer.bypassSecurityTrustHtml('<span style="color:red;">ERROR</span>');
    } else {
      return value;
    }
  }
}
