// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAoDKWZ9er2nvxum3EQXNBPvtqQBX4YH-M",
    authDomain: "prodoptadmin.firebaseapp.com",
    databaseURL: "https://prodoptadmin.firebaseio.com",
    projectId: "prodoptadmin",
    storageBucket: "prodoptadmin.appspot.com",
    messagingSenderId: "594481590113",
    appId: "1:594481590113:web:538c1f0f82b62b683b9f20",
    measurementId: "G-FHZ2RL2S74"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
